import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import bannerLR from './images/bannerLR.jpg';
import banner from './images/banner.jpg';
import bannerRes from './images/banner-res.jpg';
import bannerResLR from './images/banner-resLR.jpg';
const Banner = ({ scrollPosition }) => (React.createElement("div", { className: "container-fluid no-pad banner-por" },
    React.createElement("div", { className: "banner-bg" },
        React.createElement(LazyLoadImage, { alt: "Local Counsel Attorneys", title: "Local Counsel Attorneys", scrollPosition: scrollPosition, src: banner, placeholderSrc: bannerLR })),
    React.createElement("div", { className: "banner-bg-res" },
        React.createElement(LazyLoadImage, { alt: "Appearance Attorney Services", title: "Appearance Attorney Services", scrollPosition: scrollPosition, src: bannerRes, placeholderSrc: bannerResLR }))));
export default trackWindowScroll(Banner);
